body {
	margin: 0;
	/* background-color: #ebf4dc; */
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
	width: 0;
}

.custom_container {
	@apply px-4 md:px-6 lg:px-8 xl:px-12 self-stretch w-full m-0;
}

.sidebar__root {
	@apply text-[13px] p-2 rounded-lg transition-all duration-300 flex items-center justify-center md:justify-start gap-2;
}

.sidebar__inActive {
	@apply sidebar__root bg-transparent text-muted fill-muted hover:bg-primary/5;
}

.sidebar__active {
	@apply sidebar__root bg-primary/5 text-primary fill-primary border-l-2 border-primary font-semibold;
}

.glassmorphism {
	@apply bg-clip-border backdrop-filter backdrop-blur-md bg-opacity-20;
}
